import React from 'react';
import Button from './styles';

const PTButton = ({ label, startIcon, error, endIcon, ...rest }) => (
  <Button error={error} {...rest}>
    {startIcon ? <span className="mr-2">{startIcon}</span> : null}
    {label}
    {endIcon ? <span className="ml-2">{endIcon}</span> : null}
  </Button>
);

export default PTButton;
